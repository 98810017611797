
























































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import InvoicesTable from '@/components/InvoicesTable.vue'
import { ApiResponse, Invoice, Sale, Structure } from '@/interfaces'
import { invoiceTypes } from '@/formHelpers'
import { mapState, mapActions } from 'vuex'
import { DatePicker } from 'element-ui'

interface DropdownAction {
  action: string
  item?: Invoice
  status?: string
}

export default Vue.extend({
  components: {
    InvoicesTable,
    Nav,
  },
  data() {
    return {
      invoices: [] as Invoice[],
      search: '',
      busy: false,
      accountingExportToCreate: [] as number[],
      invoicingPeriod: [] as string[],
      invoicingPeriodOptions: {
        shortcuts: [
          {
            text: 'Mois dernier',
            onClick(picker: DatePicker) {
              const start = new Date()
              const startMonth = start.getMonth() - 1
              start.setDate(1)
              start.setMonth(startMonth)
              const end = new Date(start.getFullYear(), startMonth + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Mois en cours',
            onClick(picker: DatePicker) {
              const start = new Date()
              start.setDate(1)
              const end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      sales: [] as Sale[],
      saleFilter: null,
      structures: [] as Structure[],
      structureFilter: null,
      invoiceTypes,
      invoiceTypeFilter: null,
      selectedInvoicesNumber: 0,
      selectedInvoicesTotal: '',
      selectedInvoicesRemainingTotal: '',
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user', 'invoicesFilters']),
    selectedTotal(): string {
      return `${this.selectedInvoicesNumber} facture(s) pour un total de ${this.selectedInvoicesTotal} et restant dû de ${this.selectedInvoicesRemainingTotal}`
    },
  },
  watch: {
    user(newVal) {
      this.structures = newVal.client?.structures
      this.addons = newVal.client?.addons ?? 0
    },
  },
  mounted() {
    if (this.user) {
      this.addons = this.user.client?.addons ?? 0
    }

    if (this.$route.query.search && this.$route.query.search !== '') {
      this.search = this.$route.query.search as string
    }

    if (this.user?.client?.structures) {
      this.structures = this.user?.client?.structures
    }

    if (
      this.invoicesFilters &&
      (this.invoicesFilters.previousRoute?.includes('accounting') ||
        this.invoicesFilters.previousRoute?.includes('invoice_'))
    ) {
      this.setAccountingFilters()
    } else {
      this.setInvoicesFilters({})
    }

    //this.getInvoices()
    this.getSales()
  },
  methods: {
    ...mapActions(['setInvoicesFilters']),
    updateFilter() {
      // compatibilité avec clearable du champ
      if (this.saleFilter == '') {
        this.saleFilter = null
      }
      if (this.invoiceTypeFilter == '') {
        this.invoiceTypeFilter = null
      }
      this.setInvoicesFilters({
        structure: this.structureFilter,
        sale: this.saleFilter,
        invoiceType: this.invoiceTypeFilter,
        search: this.search,
        invoicingPeriod: this.invoicingPeriod,
        previousRoute: this.$route.name,
      })

      this.getInvoices()
    },
    getInvoices() {
      this.invoices = []
      if (!this.busy && this.structureFilter) {
        this.busy = true

        const loading = this.$loading({
          target: '#invoicesTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/accounting/invoices/list', {
            params: {
              clientName: this.search,
              invoicingPeriod: this.invoicingPeriod,
              sale: this.saleFilter,
              structure: this.structureFilter,
              invoiceType: this.invoiceTypeFilter,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.invoices = apiResponse.data
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    getSales() {
      this.$api
        .get(`/sales`, {
          params: {
            type: 'simple',
            pagination: false,
            sale: this.saleFilter,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.sales = apiResponse.data
        })
    },
    handleItemCommand(command: DropdownAction) {
      if (command.action == 'export') {
        this.generateAccountingExport(command.action)
      }
      if (command.action == 'exportCiel') {
        this.generateAccountingExport(command.action)
      }
      if (command.action == 'dayBook') {
        this.generateAccountingDaybook()
      }

      if (command.action == 'changeStatus') {
        this.updateInvoicesStatus(command.status ?? '')
      }
    },
    generateAccountingExport(type: string) {
      if (!this.busy) {
        this.$confirm(
          "Souhaitez-vous mettre à jour l'état des factures exportées à comptabilisée ?",
          'Confirmation',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            type: 'warning',
          }
        )
          .then(() => {
            this.exportInvoices(true, type)
          })
          .catch((action) => {
            if (action === 'cancel') {
              this.exportInvoices(false, type)
            }
          })
      }
    },
    exportInvoices(updateState: boolean, type: string) {
      this.busy = true
      const ids = {
        ...this.getInvoicesToExport(),
      }

      this.$api
        .post(`/accounting/mass-export/${type}`, {
          ids,
          clientName: this.search,
          invoicingPeriod: this.invoicingPeriod,
          sale: this.saleFilter,
          structure: this.structureFilter,
          invoiceType: this.invoiceTypeFilter,
          updateState,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          // Rafraichissement de la listez
          this.invoices = apiResponse.data.invoices

          const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
          window.open(documentUrl, '_blank')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    generateAccountingDaybook() {
      if (!this.busy) {
        this.busy = true
        const ids = {
          ...this.getInvoicesToExport(),
        }

        this.$api
          .post(`/accounting/mass-export/daybook`, {
            ids,
            clientName: this.search,
            invoicingPeriod: this.invoicingPeriod,
            sale: this.saleFilter,
            structure: this.structureFilter,
            invoiceType: this.invoiceTypeFilter,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            ;(this.$refs.invoiceToExportTable as InstanceType<typeof InvoicesTable>).clearSelected()
            //const documentUrl = `${process.env.VUE_APP_API_URL}/document/daybook/${apiResponse.data.filename}/${this.user.client.id}/download`
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once?contentd=inline`
            window.open(documentUrl, '_blank')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    updateInvoicesStatus(status: string) {
      if (!this.busy && status !== '') {
        this.busy = true

        const ids = {
          ...this.getInvoicesToExport(),
        }

        this.$api
          .post(`/accounting/mass-status-update/`, {
            ids,
            status,
            clientName: this.search,
            invoicingPeriod: this.invoicingPeriod,
            sale: this.saleFilter,
            structure: this.structureFilter,
            invoiceType: this.invoiceTypeFilter,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.invoices = apiResponse.data
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    getInvoicesToExport() {
      if (this.accountingExportToCreate && this.accountingExportToCreate.length === 0) {
        this.invoices.forEach((invoice) => {
          if (invoice?.id && !this.accountingExportToCreate.includes(invoice.id)) {
            this.accountingExportToCreate?.push(invoice.id)
          }
        })
      }
      return this.accountingExportToCreate
    },
    updateSelectedInvoices(selectedInvoices: Invoice[]) {
      this.selectedInvoicesNumber = selectedInvoices.length
      this.accountingExportToCreate = []
      let total = 0
      let remainingTotal = 0
      selectedInvoices.forEach((invoice) => {
        if (invoice?.id) {
          this.accountingExportToCreate?.push(invoice.id)
          total += parseFloat(invoice.priceIncludingTaxes ?? '')
          remainingTotal += invoice.remainingPayment ?? 0
        }
      })
      this.selectedInvoicesTotal = this.formatCurrency(total)
      this.selectedInvoicesRemainingTotal = this.formatCurrency(remainingTotal)
    },
    formatCurrency(number: number | null): string {
      if (!number) {
        number = 0
      }
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number)
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
    setAccountingFilters() {
      this.saleFilter = this.invoicesFilters?.sale
      this.structureFilter = this.invoicesFilters?.structure
      this.search = this.invoicesFilters?.search
      this.invoiceTypeFilter = this.invoicesFilters?.invoiceType
      this.invoicingPeriod = this.invoicesFilters?.invoicingPeriod
    },
  },
})
